<template>
    <div class="mt-4">
        <div class="col-7 float-left">
            <pretty-select
                placeholder="Claim"
                :options="availableClaims"
                :get-option-label="claim => claim.label"
                :get-option-key="claim => claim.value"
                :reduce="claim => claim.value"
                :multiple="true"
                v-model="value.claim"
            ></pretty-select>
        </div>
    </div>
</template>
<script>

import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'CatalogProductSettingClaims',
    components: { PrettySelect },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        return {
            availableClaims: [
                { value: 'LIM', label: 'Low Impact Materials' },
                { value: 'SU', label: 'Sustainable Use' },
                { value: 'PP', label: 'People and Planet' },
                { value: 'RHS', label: 'Reduced Harmful Substances' },
                { value: 'TPC', label: 'Third-Party Certified and/or Verified' },
                { value: 'LIM1', label: '100% bio-sourced material' },
                { value: 'LIM2', label: '100% recycled material' },
                { value: 'LIM3', label: '100% Mix of recycled and bio-sourced materials' },
                { value: 'LIM4', label: 'At least 80% recycled material' },
                { value: 'LIM5', label: 'At least 80% bio-sourced material' },
                { value: 'LIM6', label: 'At least 50% recycled material' },
                { value: 'LIM7', label: 'At least 50% bio-sourced material' },
                { value: 'LIM8', label: 'Mix of recycled and virgin materials' },
                { value: 'LIM9', label: 'Mix of bio-sourced and virgin materials' },
                { value: 'LIM10', label: 'Made of reused material' },
                { value: 'EOL1', label: '100% recyclable material' },
                { value: 'EOL2', label: '100% compostable material' },
                { value: 'EOL3', label: 'Extended life cycle' },
                { value: 'RHS1', label: 'BPA free' },
                { value: 'RHS2', label: 'PVC free' },
                { value: 'RHS3', label: 'DEHP free' },
                { value: 'RHS4', label: 'Non toxic inks and dyes' },
                { value: 'RHS5', label: 'Chlorine free' },
            ],
            configuration: {
                claim: null,
            },
        }
    },
}
</script>
<style scoped lang="scss">
.float-left {
    float: left;
    margin-left: -13px;
}
</style>
